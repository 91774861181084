import Swal from 'sweetalert2';
import { API_HUBSPOT, API_PACIENTE_PROPUESTA } from '../Constants/Api';
import { ApiRequest } from './ApiRequest';

export const getPatientAPI = async (item, dataLocal, self) => {
  let country = self.countrySelect || self.country;
  sessionStorage.removeItem('arrayMedicine');
  sessionStorage.removeItem('arregloArticulos');
  try {
    let userInfo;
    if (item === 'email' && country === 'US') {
      self.isSearchEmail = true;
      userInfo = await ApiRequest.get(`${API_PACIENTE_PROPUESTA}correo/${self.getUserEmail}`);
    } else if (item === 'nhc' && country === 'US') {
      self.isSearchNhc = true;
      userInfo = await ApiRequest.get(`${API_HUBSPOT}validaSSPTAByNhc/nhc/${self.nhcNumber}`);
    } else if (item === 'email' && country === 'MX') {
      self.isSearchEmail = true;
      userInfo = await ApiRequest.get(`${API_HUBSPOT}ssptaMx/correo/${self.getUserEmail}`);
    } else if (item === 'nhc' && country === 'MX') {
      self.isSearchNhc = true;
      userInfo = await ApiRequest.get(`${API_HUBSPOT}validaSSPTAByNhcMX/nhc_def_mx/${self.nhcNumber}`);
    }

    await dataLocal(userInfo);
    self.getUserEmail = '';
    self.nhcNumber = '';
  } catch (error) {
    if (error.status === 400) {
      return Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'The patient did NOT attend the appointment',
      });
    }
    if (error.status === 500) {
      //let infoError = 'owner_id'; // validar mensajes de error
      //let correo = error.response.data.msg.split(' ')[2]
      return Swal.fire({
        icon: 'warning',
        title: 'Warning',
        //text:  `${this.$i18n.t(`welcome.error.contact`)} ${correo} ${this.$i18n.t(`welcome.error.${infoError}`)}`,
        text: `${error.data.msg}`,
      });
    }
    Swal.fire({
      title: 'Warning',
      text: 'An error occurred while searching for the patient',
      icon: 'error',
    });
  } finally {
    self.isSearchEmail = false;
    self.isSearchNhc = false;
  }
};
